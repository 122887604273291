import { graphql } from "gatsby";
import React, { useState } from "react";
import Image from "gatsby-image";
import { Trans, useI18next } from "gatsby-plugin-react-i18next";

import Layout from "../../components/layout/layout";
import SEO from "../../components/seo/seo";

export const pageQuery = graphql`
  query($language: String!) {
    media_1: file(relativePath: { eq: "web/images-web-1.png" }) {
      ...fluidImage
    }
    media_2_es: file(relativePath: { eq: "web/images-web-2-ESP.png" }) {
      ...fluidImage
    }
    media_2_en: file(relativePath: { eq: "web/images-web-2-ING.png" }) {
      ...fluidImage
    }
    media_3_es: file(relativePath: { eq: "web/images-web-3-ESP.png" }) {
      ...fluidImage
    }
    media_3_en: file(relativePath: { eq: "web/images-web-3-ING.png" }) {
      ...fluidImage
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const Web = ({ data }) => {
  const [textShown, setTextShown] = useState(false);

  const { language } = useI18next();
  const media_2 = language === "en" ? data.media_2_en : data.media_2_es;
  const media_3 = language === "en" ? data.media_3_en : data.media_3_es;

  const shortDescription =
    "Hacemos páginas web bonitas y eficientes con JAMstack y CMS tradicionales como Wordpress.";

  const description = [
    "En Estudio 94, nos especializamos en el diseño y desarrollo de webs estáticas y single-page para tu negocio. Trabajamos con tecnologías Jamstack y con sistemas CMS tradicionales, como WordPress, para asegurar una buena experiencia de usuario para ti y para los visitantes.",
    "Hoy en día, es esencial que las páginas web se puedan visualizar en un abanico de dispositivos y pantallas, por lo que en Estudio 94 siempre seguimos una estrategia mobile-first. Esto significa que siempre desarrollamos nuestras plantillas pensando en móviles y pantallas pequeñas, garantizando que puedas disfrutar de una experiencia de primera clase estés donde estés.",
    "También creemos que, en la era de las redes sociales, es más importante que nunca centrarse en las comunicaciones y la optimización de búsqueda. Por este motivo, aseguramos que tu página web esté preparada para trabajar con los principales motores de búsqueda y redes sociales. También nos gusta trabajar con 'newsletters', que pueden ser una herramienta muy efectiva para mantener informados a tus clientes y promocionarte en la esfera digital.",
  ];

  return (
    <Layout pagename="web_services">
      <SEO title="Diseño web" description={shortDescription} />
      <div className="page_content">
        <div className="service__text">
          <h3 className="service__title">
            <Trans>Diseño web</Trans>
          </h3>
          <p className="service__description">
            <Trans>{description[0]}</Trans>
          </p>
          <div
            className={
              "service__description_extra" +
              (!textShown ? " service__description_extra--hidden" : "")
            }
          >
            {description.slice(1).map((paragraph) => (
              <p className="service__description">
                <Trans>{paragraph}</Trans>
              </p>
            ))}
          </div>
          <button
            type="button"
            className="service__description_button"
            onClick={() => setTextShown(!textShown)}
          ></button>
        </div>
        <div className="service__media">
          <div className="media_group media_group--stacked">
            <Image fluid={data.media_1.childImageSharp.fluid} />
            <Image fluid={media_3.childImageSharp.fluid} />
          </div>
          <div className="media_group">
            <Image fluid={media_2.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Web;
